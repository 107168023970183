.coupon-list {
  margin-bottom: 64px;
  padding: 0 16px;

  @media #{$breakpoint-above-tablet} {
    padding: 0;
  }

  .coupons {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 32px;
    align-items: stretch;
    justify-content: space-evenly;

    @media #{$breakpoint-above-tablet} {
      gap: 24px;
      justify-content: flex-start;
    }
  }

  .coupon {
    flex-grow: 2;
    flex-basis: 47%;
    max-width: 47%;
    position: relative;

    @media #{$breakpoint-above-tablet} {
      flex-basis: 23%;
      max-width: 23%;
    }

    &--image {
      aspect-ratio: 4 / 3;
      margin-bottom: -72px;
      position: relative;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &--info {
      position: relative;
      z-index: 1;
      color: $Brand-Black;
      border-radius: 0 0 8px 8px;
      padding: 80px 8px 16px;

      @media #{$breakpoint-above-tablet} {
        padding: 80px 24px 24px;
      }
    }

    &--background {
      background-color: $white;
      border-radius: 0 0 8px 8px;
      padding: 0 24px 24px;
      box-shadow: $Brand-Red 6px 6px 0 0;
      height: calc(100% - 85px);
      width: 100%;
      z-index: 0;
      position: absolute;
      bottom: 0;

      @media #{$breakpoint-above-mobile} {
        height: calc(100% - 175px);
      }

      @media #{$breakpoint-above-tablet} {
        height: calc(100% - 125px);
      }
    }

    &--title {
      font-family: $National2Condensed;
      font-size: 20px;
      font-weight: $font-weight-bold;
      line-height: 100%;
      overflow-wrap: break-word;
      text-transform: uppercase;

      @media #{$breakpoint-above-tablet} {
        font-size: 24px;
      }
    }

    &--price {
      font-family: $National2Regular;
      font-size: 16px;
      line-height: 150%;
    }

    &--strikethrough {
      color: $Brand-Red;
      padding-right: 8px;
    }
  }
}
