.flexible-component {
  &--header-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 48px 16px 24px;

    @media #{$breakpoint-above-tablet} {
      padding: 48px 0 24px;
    }
  }
  &--header {
    font-family: $National2Condensed;
    font-size: 36px;
    font-weight: $font-weight-bold;
    line-height: 100%;
    margin-bottom: 8px;
    text-transform: uppercase;

    @media #{$breakpoint-above-tablet} {
      font-size: 48px;
      margin-bottom: 24px;
    }
  }
  &--sub-header-copy {
    font-family: $National2Regular;
    font-size: 16px;
    line-height: 150%;
    p {
      margin-bottom: 0;
    }
  }
  &--pictogram {
    width: 80px;
    height: 80px;
  }
}
